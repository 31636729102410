import React from "react"
import { Link } from "gatsby"
import { Formik } from "formik"
import Recaptcha from "react-recaptcha"
import axios from "axios"
import qs from "qs"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submitted: false }
    this.setSubmittedState = this.setSubmittedState.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }

  _drawFormFields(fields, formikProps) {
    const formFields = fields.map(f => {
      return (
        <div key={f.name} className="field">
          <label
            htmlFor={f.name}
            style={{ display: "block" }}
            className="label"
          >
            {f.label}
          </label>
          <div className="control">
            {f.type === "text" && (
              <input
                id={f.name}
                placeholder={f.placeholder}
                type="text"
                value={formikProps.values[f.name]}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                className={
                  (formikProps.errors[f.name] && formikProps.touched[f.name]
                    ? "text-input error"
                    : "text-input") + " input"
                }
              />
            )}
            {f.type === "textarea" && (
              <textarea
                id={f.name}
                placeholder={f.placeholder}
                rows="5"
                value={formikProps.values[f.name]}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                className={
                  formikProps.errors[f.name] && formikProps.touched[f.name]
                    ? "textarea error"
                    : "textarea"
                }
              />
            )}
          </div>
          {formikProps.errors[f.name] && formikProps.touched[f.name] && (
            <p className="help is-danger">{formikProps.errors[f.name]}</p>
          )}
        </div>
      )
    })

    return formFields
  }

  setSubmittedState(status) {
    this.setState({ submitted: status })
  }

  render() {
    return (
      <Formik
        initialValues={this.props.formData.initialValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios
            .post(
              "https://webadmin.tensorplc.com/mail/send.php",
              qs.stringify({
                formData: values,
                formName: this.props.formData.formName,
              })
            )
            .then(res => {
              setSubmitting(false)
              resetForm()
              this.setSubmittedState(true)
            })
            .catch(err => {
              setSubmitting(false)
            })
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          // }, 500);
        }}
        validationSchema={this.props.formData.validationSchema}
      >
        {props => {
          const {
            errors,
            values,
            submitCount,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props
          return (
            <form onSubmit={handleSubmit}>
              {this._drawFormFields(this.props.formData.formFields, props)}

              {this.props.formData.recaptcha.display && (
                <div className="field">
                  <label htmlFor="recaptcha" className="label is-hidden">
                    Recaptcha Validation
                  </label>
                  <Recaptcha
                    sitekey="6LfYm-MUAAAAAHHXx1u-Dod6JlcuQme4glf5ZCQ7"
                    render="explicit"
                    theme={this.props.formData.recaptcha.theme}
                    verifyCallback={response => {
                      setFieldValue("recaptcha", response)
                    }}
                    onloadCallback={() => {
                      console.log("done loading!")
                    }}
                  />
                  {errors.recaptcha && submitCount > 0 && (
                    <p className="help is-danger">{errors.recaptcha}</p>
                  )}
                </div>
              )}

              {this.props.formData.privacyPolicy.display && (
                <div className="field">
                  <label htmlFor="privacyPolicy" className="label">
                    Please read our Privacy Policy before submitting your
                    message.
                  </label>
                  <label className="checkbox">
                    <input
                      id="privacyPolicy"
                      type="checkbox"
                      value={values.privacyPolicy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    I acknowledge and agree to the Privacy Policy, available{" "}
                    <Link to="/privacy-policy">here</Link>.
                  </label>

                  {errors.privacyPolicy && submitCount > 0 && (
                    <p className="help is-danger">{errors.privacyPolicy}</p>
                  )}
                </div>
              )}

              <p>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button is-link"
                >
                  Submit
                </button>
              </p>

              {this.state.submitted && (
                <div
                  className="notification is-success"
                  style={{ margin: "1rem 0 0 0" }}
                >
                  <button
                    className="delete"
                    onClick={e => this.setSubmittedState(false)}
                  ></button>
                  Your message was sent successfully. Thanks.
                </div>
              )}
            </form>
          )
        }}
      </Formik>
    )
  }
}

export default ContactForm
