import React from "react"
import * as Yup from "yup"
import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm/ContactForm"
import SEO from "../components/SEO"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

const DrawMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 52.244022, lng: -0.296685 }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: 52.244022, lng: -0.296685 }} />
      )}
    </GoogleMap>
  ))
)

const requiredError = "Please fill in the required field."
const _formData = {
  formName: "timekeeping-contact",
  recaptcha: {
    display: true,
    theme: "light",
  },
  privacyPolicy: {
    display: true,
  },
  validationSchema: Yup.object().shape({
    Name: Yup.string().required(requiredError),
    Email: Yup.string()
      .email()
      .required(requiredError),
    Telephone: Yup.string().required(requiredError),
    Enquiry: Yup.string().required(requiredError),
    recaptcha: Yup.string().required("Recaptcha response is required."),
    privacyPolicy: Yup.boolean()
      .oneOf([true], "Privacy Policy agreement is required.")
      .required("Privacy Policy agreement is required."),
  }),
  formFields: [
    {
      name: "Name",
      label: "Name",
      placeholder: "Your name",
      type: "text",
    },
    {
      name: "Email",
      label: "Email Address",
      placeholder: "Your email",
      type: "text",
    },
    {
      name: "Telephone",
      label: "Telephone",
      placeholder: "Your telephone",
      type: "text",
    },
    {
      name: "Enquiry",
      label: "Enquiry",
      placeholder: "Your message",
      type: "textarea",
    },
  ],
  initialValues: {
    Name: "",
    Email: "",
    Telephone: "",
    Enquiry: "",
  },
}

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <section className="page-wrapper container is-fluid">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1 has-text-centered">
              <h2 className="is-size-2 has-text-weight-bold is-bold-light has-text-centered">
                Contact
              </h2>
              <p className="is-size-4">We'll be happy to help</p>
              <hr className="is-6" />
            </div>
          </div>
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <ContactForm formData={_formData} />
            </div>
          </div>
        </div>
        <hr className="is-6" />
        <DrawMap
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmKOimZlJmP8ZRCW5ZfpJaHyJdx0-2G-g&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `480px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </section>
    </Layout>
  )
}

export default ContactPage
